import {
    Create,
    regex,
    required,
    SimpleForm,
    TextInput,
    useRefresh,
} from 'react-admin'
import React from 'react'
import { urlRegex } from '../../constants/regex'
import { PessimisticEdit } from '../../components/PessimisticEdit'

export const CreateCampaignSurveyForm = () => {
    const refresh = useRefresh()
    const onSuccess = () => {
        refresh()
    }

    return (
        <Create
            title="Manage "
            mutationOptions={{ onSuccess }}
            redirect={false}
        >
            <CampaignSurveyForm title="Schedule Automate Survey" />
        </Create>
    )
}

export const EditCampaignSurveyForm = () => {
    return (
        <PessimisticEdit title="Edit Automate Survey" redirect={false}>
            <CampaignSurveyForm title="Edit Automate Survey" />
        </PessimisticEdit>
    )
}

type CampaignSurveyFormProps = {
    title: string
}

export const CampaignSurveyForm = ({ title }: CampaignSurveyFormProps) => {
    return (
        <SimpleForm>
            <div style={{ width: '100%' }}>
                <p>{title}</p>
                <hr />
                <div
                    style={{
                        width: '40%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <CampaignSurveyUrlInput />
                </div>
            </div>
        </SimpleForm>
    )
}

const CampaignSurveyUrlInput = () => {
    return (
        <TextInput
            source="url"
            label="Url for survey"
            validate={[
                required('Please provide url for survey'),
                regex(urlRegex, 'Please provide correct url'),
            ]}
        />
    )
}
