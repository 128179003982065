import { z } from 'zod'
import { daredropApiUrl, daredropUrl } from '../constants/app'
import requestHandler from './requestHandler'
import { AuthApi_Group } from '../services/auth-api/auth-api.types'
import { TokensService } from '../services/tokens.service'

const adminGroups = ['admin', 'superAdmin'] satisfies AuthApi_Group[]

const authApiGroups = [
    'agency',
    'agencyAccountManager',
    'creator',
    'viewer',
    'brand',
    'betaTester',
    'enterprise',
    'clipReviewer',
    ...adminGroups,
] as const satisfies Readonly<AuthApi_Group[]>

export const authApiTokenSchema = z.object({
    groups: z.array(z.enum(authApiGroups)),
    email: z.string(),
    userId: z.string(),
    exp: z.number(),
})

type SignInAsResponse =
    | {
          body: {
              accessToken: string
              refreshToken: string
          }
          error: never
      }
    | { error: unknown; body: never }

export const signInAs = async (userId: string) => {
    const { body, error } = await requestHandler<SignInAsResponse>({
        headers: {},
        url: daredropApiUrl,
        method: 'POST',
        body: {
            endpointId: 'AUTH_API_PROXY',
            payload: {
                url: 'internal/sign-in',
                method: 'POST',
                body: {
                    userId,
                },
            },
            authentication: TokensService.getAppToken(),
        },
    })

    if (error && !body) {
        // eslint-disable-next-line no-console
        console.error(error)
        throw new Error(
            'Something went wrong, checkout console for more information'
        )
    }
    const { accessToken, refreshToken } = body
    const url = new URL(`${daredropUrl}/drops`)

    const hash = new URLSearchParams({
        accessToken,
        refreshToken,
    })

    url.hash = hash.toString()
    window.open(url.href, '_blank')
}
