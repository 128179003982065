import { useState } from 'react'
import { Confirm, useNotify, useRecordContext } from 'react-admin'
import { useMutation } from '@tanstack/react-query'
import { Button } from '@mui/material'
import { Delete } from '@material-ui/icons'
import { RaRecord } from '../../types'
import { AuthApi_User } from '../../services/auth-api/auth-api.types'
import { DaredropApi } from '../../utils/daredrop-api'

export const ClearRewardsButton = () => {
    const [open, setOpen] = useState(false)

    const notify = useNotify()
    const { id, groups = [] } = useRecordContext<RaRecord<AuthApi_User>>() || {}

    const mutation = useMutation({
        mutationFn: async (brandId: string) => {
            await DaredropApi.wipeAccount(brandId)
        },
    })

    const handleClick = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleConfirm = async () => {
        await mutation.mutateAsync(id as string)
        notify('Rewards will be cleaned in couple minutes')
        setOpen(false)
    }

    const isBrand = groups.includes('brand')

    if (!isBrand) {
        return null
    }

    return (
        <>
            <Confirm
                isOpen={open}
                title={`Clear rewards (${id})`}
                content="Are you sure you want to clear rewards?"
                onClose={handleClose}
                onConfirm={handleConfirm}
                loading={mutation.isLoading}
            />
            <Button
                size="small"
                startIcon={<Delete />}
                color="primary"
                onClick={handleClick}
            >
                Clear rewards
            </Button>
        </>
    )
}
