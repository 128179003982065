import { ChangeEvent, useState } from 'react'

export type HelperTextHandler = (value: string) => string

export const useHelperText = (
    getHelperTextHandler: HelperTextHandler,
    initialValue?: string | number
) => {
    const [helperText, setHelperText] = useState<string>(() => {
        return initialValue ? getHelperTextHandler(String(initialValue)) : ''
    })

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setHelperText(getHelperTextHandler(event?.target?.value))
    }

    return [helperText, onChange] as const
}
