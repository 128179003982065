import {
    SimpleForm,
    TextInput,
    BooleanInput,
    SelectArrayInput,
    DateTimeInput,
    TopToolbar,
    ListButton,
    useRecordContext,
    useEditContext,
} from 'react-admin'
import { Button } from '@mui/material'
import LoginIcon from '@material-ui/icons/Input'
import { signInAs } from '../../utils/authApi'
import { PessimisticEdit } from '../../components/PessimisticEdit'
import { ClearRewardsButton } from './ClearRewardsButton'
import { AuthApiUser } from '../../resourceDescriptions/users-list'
import { RaRecord } from '../../types'

const UserEditActions = () => {
    const { id } = useRecordContext() || {}
    return (
        <TopToolbar>
            <ClearRewardsButton />
            <ListButton />
            <Button
                size="small"
                startIcon={<LoginIcon />}
                color="primary"
                onClick={async () => {
                    await signInAs(id as string)
                }}
            >
                Sign in as
            </Button>
        </TopToolbar>
    )
}

const User = () => {
    return (
        <PessimisticEdit actions={<UserEditActions />}>
            <SimpleForm>
                <TextInput fullWidth label="User id" source="pk" disabled />
                <TextInput fullWidth label="Email" source="email" />
                <BooleanInput label="Confirmed" source="isConfirmed" />
                <DateTimeInput source="confirmedAt" disabled />
                <BooleanInput label="Banned" source="isBanned" />
                <SelectArrayInput
                    source="groups"
                    choices={[
                        { id: 'enterprise', name: 'Enterprise' },
                        { id: 'clipReviewer', name: 'Clip reviewer' },
                        { id: 'betaTester', name: 'Beta tester' },
                        { id: 'viewer', name: 'Viewer' },
                        { id: 'creator', name: 'Creator' },
                        { id: 'brand', name: 'Brand' },
                        { id: 'brandUser', name: 'Brand User' },
                        { id: 'brandAdmin', name: 'Brand Admin' },
                        { id: 'agency', name: 'Agency' },
                        {
                            id: 'agencyAccountManager',
                            name: 'Agency account manager',
                        },
                        { id: 'admin', name: 'Admin' },
                    ]}
                />
                <ReviewerStats />
            </SimpleForm>
        </PessimisticEdit>
    )
}

const ReviewerStats = () => {
    const { record } = useEditContext<RaRecord<AuthApiUser>>()
    const isReviewer = record?.groups.includes('clipReviewer')

    if (!isReviewer) {
        return null
    }

    return (
        <>
            <TextInput
                label="Correct Decisions (%)"
                source="avgCorrectDecisions"
                format={(value) => {
                    if (!value) {
                        return 0
                    }

                    return parseFloat(value.toFixed(3)) * 100
                }}
                disabled
            />
            <TextInput label="Total Reviews" source="totalReviews" disabled />
        </>
    )
}

export default User
