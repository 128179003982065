import {
    Datagrid,
    DeleteButton,
    EditButton,
    List,
    TextField,
} from 'react-admin'
import React from 'react'
import { getCurrentQueryValue } from '../../utils/common'
import { CreateCampaignSurveyForm } from './CampaignSurveyForm'

export const CampaignSurveyList = () => {
    const dropId = getCurrentQueryValue()
    if (!dropId) {
        return null
    }

    return (
        <>
            <CreateCampaignSurveyForm />
            <List empty={false}>
                <Datagrid>
                    <TextField label="Url" source="url" />
                    <DeleteButton redirect={false} />
                    <EditButton />
                </Datagrid>
            </List>
        </>
    )
}
