import { resourcesIds } from 'resources'
import {
    deleteKey,
    deleteManyKey,
    editListItemsIdKey,
    editOneItemIdKey,
    getListKey,
    getOneKey,
    updateKey,
} from '../keys'
import { AuthApi_Group } from '../services/auth-api/auth-api.types'
import { TokensService } from '../services/tokens.service'

export type AuthApiUser = {
    pk: string
    sk: string
    groups: AuthApi_Group[]
    email: string
    isConfirmed: boolean
    isBanned: boolean
    confirmedAt?: string
    avgCorrectDecisions?: number
    totalReviews?: number
}

export default {
    [resourcesIds.USERS_LIST_RESOURCE_ID]: {
        [getListKey]: (data: {
            pagination: { perPage: number; page: number }
            filter: { value: string; filter: string }
        }) => {
            const {
                pagination: { perPage, page },
                filter,
            } = data
            return {
                pagination: true,
                endpointId: 'AUTH_API_PROXY',
                payload: {
                    url: 'internal/users',
                    method: 'GET',
                    query: {
                        limit: perPage,
                        page,
                        ...filter,
                    },
                },
                authentication: TokensService.getAppToken(),
            }
        },
        [editListItemsIdKey]: (data: AuthApiUser[]) =>
            data.map((user) => ({ ...user, id: user.pk })),
        [deleteKey]: (params: Record<string, any>) => {
            const { id } = params
            return {
                endpointId: 'AUTH_API_PROXY',
                payload: {
                    url: 'internal/user',
                    method: 'DELETE',
                    body: {
                        userId: id,
                    },
                },
                authentication: TokensService.getAppToken(),
            }
        },
        [editOneItemIdKey]: (data: AuthApiUser) => ({
            ...data,
            id: data.pk,
        }),
        [getOneKey]: ({ id }: { id: string }) => ({
            endpointId: 'GET_USER_ADMIN',
            payload: {
                userId: id,
            },
            authentication: TokensService.getAppToken(),
        }),
        [updateKey]: (params: { data: AuthApiUser }) => {
            const { data } = params
            const { pk: userId, groups, isBanned, email, isConfirmed } = data
            return {
                endpointId: 'AUTH_API_PROXY',
                payload: {
                    url: 'internal/user',
                    method: 'PATCH',
                    body: {
                        userId,
                        groups,
                        isBanned,
                        email,
                        isConfirmed,
                    },
                },
                authentication: TokensService.getAppToken(),
            }
        },
        [deleteManyKey]: (data: { ids: AuthApiUser['pk'][] }) =>
            data.ids.map((id) => ({
                endpointId: 'AUTH_API_PROXY',
                payload: {
                    url: 'internal/user',
                    method: 'DELETE',
                    body: {
                        userId: id,
                    },
                },
                authentication: TokensService.getAppToken(),
            })),
    },
}
