import { DOLLARS_TO_POINTS_COEFFICIENT } from 'constants/converter'

export const pointsToDollarsHelperText = (value: string) => {
    const points = Math.floor(parseInt(value, 10))
    if (Number.isNaN(value)) {
        return 'Equivalent $0 dollar value'
    }
    return `Equivalent $${(
        points / DOLLARS_TO_POINTS_COEFFICIENT
    ).toLocaleString()} dollar value`
}
